import React from "react"
import Seo from "../components/SEO"
import Layout from "../components/layout"
import PostThumbs from "../components/Sections/PostThumbs"
import ThreeCards from "../components/Sections/ThreeCards"
import WidgetJumbotron from "../components/Sections/WidgetJumbotron"

const HomePage = () => {
  return (
    <Layout>
      <Seo title="homepage" />

      <WidgetJumbotron />

      <PostThumbs />
      <ThreeCards />
    </Layout>
  )
}

export default HomePage
