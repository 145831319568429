import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
const PostThumbs = () => {
  const {
    allMdx: { edges },
  } = useStaticQuery(graphql`
    query allMDXposts {
      allMdx {
        edges {
          node {
            frontmatter {
              slug
              title
            }
          }
        }
      }
    }
  `)

  // console.log(edges)
  return (
    <div className=" border-black dark:border-white border-2 px-8 py-4 mb-4">
      <h3>Tutti gli articoli</h3>
      <div>
        {edges.map((item, index) => {
          return (
            <div
              key={`thumb_${index}`}
              className="border-black dark:border-white border-2 p-2"
            >
              <Link to={item.node.frontmatter.slug}>
                {item.node.frontmatter.title}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PostThumbs
