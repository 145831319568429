import React from "react"
import Clessidra from "../../assets/clessidra.svg"
import Coins from "../../assets/coins.svg"
import Graph from "../../assets/graph.svg"
import data from "../../data/vantaggi.json"

const IconRouter = ({ which }) => {
  return (
    <>
      {
        {
          clessidra: <Clessidra />,
          coins: <Coins />,
          graph: <Graph />,
        }[which]
      }
    </>
  )
}

const ThreeCards = () => {
  // console.log(data.content)
  return (
    <div className=" p-2 px-8 mb-6">
      <h2 className="lg:text-3xl md:text-2xl text-xl text-center py-4 font-bold">
        {data.title}
      </h2>
      <div className="p-2 grid lg:grid-cols-3 grid-cols-1 gap-10">
        {data.content.map((card, index) => {
          return (
            <div
              key={index}
              className="p-8 flex flex-col items-center justify-between shadow-md rounded dark:bg-gray-700 md:px-8 hover:shadow-xl duration-300"
            >
              <h3 className="pb-4 font-bold md:text-xl sm:text-md uppercase text-center">
                {card.title}
              </h3>

              {card.icon !== "" && (
                <div className="pb-4">
                  <IconRouter which={card.icon} />
                </div>
              )}

              <p className="text-md text-center">{card.description}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ThreeCards
