import React from "react"
// import AnalisiEconomica from "../../assets/widgets/analisi-economica.svg"
import Fattura from "../../assets/widgets/fattura.svg"
import data from "../../data/widgetJumbo.json"
import { AnimatePresence, motion } from "framer-motion"

const widget = {
  initial: {
    opacity: 0.02,
    y: "100px",
  },
  animate: {
    opacity: 1,
    y: "0",
  },
}
const texts = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
}
const WidgetJumbotron = () => {
  console.log(data)
  return (
    <div className="py-8 px-8  mb-4 bg-[#DCFCF3] dark:bg-gray-800 flex flex-col gap-y-8 min-h-[60vh]">
      <AnimatePresence>
        <motion.div
          variants={texts}
          initial="initial"
          animate="animate"
          className="text-center px-4"
        >
          <h2 className="font-bold text-5xl pb-4 dark:text-[#DCFCF3]">
            {data.title}
          </h2>
          <p>{data.description}</p>
        </motion.div>
      </AnimatePresence>

      <AnimatePresence>
        <motion.div
          variants={widget}
          initial="initial"
          animate="animate"
          transition={{ delay: 0.12, type: "tween" }}
          className="flex items-center justify-center"
        >
          <Fattura className="svg-shadow h-full w-full max-h-[500px]" />
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
export default WidgetJumbotron
